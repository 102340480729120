import { Box, Card, Grid } from '@material-ui/core';

export default function Apps() {
  let cardStyle = {
    height: '120px',
    background: '#ff77cc'
  };
  const apps = [
    { name: 'Dream Kitchen', location: 'Innaloo' },
    { name: 'VR Hybrid' }
  ];

  const appList = apps.map(({ name, location }, index) => (
    <Grid item sm={3} height={'120px'} key={index}>
      <Box boxshadow={8}>
        <Card variant={'outlined'} rounded={5} style={cardStyle} boxshadow={5}>
          {name} {location}
        </Card>
      </Box>
    </Grid>
  ));

  const al = false;
  return (
    <div>
      <h3>Apps</h3>
      <Grid container spacing={2}>
        {al && appList}
      </Grid>
    </div>
  );
}
