import { createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../services/AxiosService';
import { selectAuthorizationHeader, updateAuthWithNewUserData } from './auth';

const initialState = {
  data: [],
  totalLength: 0
};

function updatePrimaryRole(user) {
  if (user.super_admin) {
    user.primary_role = 'superadmin';
    user.primary_organisation_id = null;
  } else if (user.permissions.length > 0) {
    user.primary_role = user.permissions[0].role;
    user.primary_organisation_id = user.permissions[0].organisation_id;
  } else {
    user.primary_role = 'None';
    user.primary_organisation_id = null;
  }
}

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUsers(state, action) {
      state.data = action.payload.users;
      state.data.forEach((user) => {
        updatePrimaryRole(user);
      });
      state.totalLength = state.data.length;
    },
    updateUserRecord(state, action) {
      const user = action.payload;
      console.log('got new user to put in store:', user);
      updatePrimaryRole(user);
      const i = state.data.findIndex((u) => u.id === user.id);
      if (i >= 0) {
        state.data[i] = user;
      } else {
        state.data.push(user);
        state.totalLength = state.data.length;
      }
    },
    removeUserRecord(state, action) {
      const id = action.payload;
      console.log('removing user with id', id);
      state.data = state.data.filter((u) => u.id !== id);
      state.totalLength = state.data.length;
    }
  }
});

export const { updateUsers, updateUserRecord, removeUserRecord } =
  slice.actions;

export const fetchUsers = () => async (dispatch, getState) => {
  console.log('fetching users...');
  const authorizationHeader = selectAuthorizationHeader(getState());
  const response = await AxiosService.get('/1/users', {
    headers: authorizationHeader
  });
  dispatch(updateUsers(response.data));

  const state = getState();
  const authuser = state.users.data.find(({ id }) => state.auth.userID === id);
  dispatch(updateAuthWithNewUserData(authuser));
};

export const fetchUser = (id) => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  try {
    const response = await AxiosService.get(`/1/user/${id}`, {
      headers: authorizationHeader
    });
    console.log('fetched a single user user, received ', response);
    dispatch(updateUserRecord(response.data));
  } catch (e) {
    console.log('failed with', e);
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  var request;
  if (data.id === undefined) {
    console.log('posting user', data);
    request = AxiosService.post('/1/users', data, {
      headers: authorizationHeader
    });
  } else {
    console.log('patching user', data);
    request = AxiosService.patch(`/1/user/${data.id}`, data, {
      headers: authorizationHeader
    });
  }
  try {
    const response = await request;
    dispatch(
      updateUserRecord({ primary_role: data.primary_role, ...response.data })
    );
  } catch (e) {
    //dispatch(updateUserRecord(data));  // TODO  - drop this once we have roles roundtripping.
    console.log('failed with', e);
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  console.log('deleting ' + id);
  const authorizationHeader = selectAuthorizationHeader(getState());
  const request = AxiosService.delete(`/1/user/${id}`, {
    headers: authorizationHeader
  });
  try {
    const response = await request;
    console.log('received response of', response);
    dispatch(removeUserRecord(id));
    //response.data is the updated record.  Need to update the state.
  } catch (e) {
    console.log('failed with', e);
  }
};

export const reducer = slice.reducer;

export function preloadedState() {
  return initialState;
}
