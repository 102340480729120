import { configureStore } from '@reduxjs/toolkit';
import * as organisations from './slices/organisations';
import * as users from './slices/users';
import * as machines from './slices/machines';
import * as sessions from './slices/sessions';
import * as auth from './slices/auth';
import { sessionsApi } from './queries/sessions';

const initialState = {
  organisations: organisations.preloadedState(),
  users: users.preloadedState(),
  machines: machines.preloadedState(),
  sessions: sessions.preloadedState(),
  auth: auth.preloadedState()
};

const store = configureStore({
  reducer: {
    organisations: organisations.reducer,
    users: users.reducer,
    machines: machines.reducer,
    sessions: sessions.reducer,
    auth: auth.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer
  },
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sessionsApi.middleware),
  preloadedState: initialState
});

export default store;
