import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FiltersBar from '../components/FiltersBar/FiltersBar';
import GridContainer, { Row } from '../components/FiltersBar/GridContainer';
import SummaryTable from '../components/Tables/Summary';
import SessionsByTimeOfDay from '../components/VPBarStack/Graphs/SessionsByTimeOfDay';
import { fetchMachines } from '../store/slices/machines';
import { SESSIONS_FILTERS, territories } from '../constants';
import { useMetaGraph } from '../components/VPBarStack/useMetaGraph';
import AverageSessions from '../components/VPBarStack/Graphs/AverageSessions';
import AverageTimePerSessions from '../components/VPBarStack/Graphs/AverageTimePerSessions';
import SessionsPerState from '../components/VPBarStack/Graphs/SessionsPerState';
import { useFilterSessions } from '../hooks/useFilterSessions';
import { DateTime } from 'luxon';
import { getDates } from '../utils';
import { useFetchSessionsAllQuery } from '../store/queries/sessions';
const Sessions = () => {
  const [activeFilter, setActiveFilter] = useState(SESSIONS_FILTERS[0]);
  const [selectedFilters, setSelectedFilters] = useState({
    machines: [],
    territories: []
  });
  const machines = useSelector((s) => s.machines);
  const dispatch = useDispatch();
  const dates = useMemo(() => getDates({ activeFilter }), [activeFilter]);
  const pastDates = getDates({
    stepInPast: true,
    now: DateTime.fromJSDate(dates[1]),
    activeFilter
  });
  const { data: sessions = [] } = useFetchSessionsAllQuery({
    dates: [dates, pastDates],
    type: activeFilter.key
  });

  // async effects -------------------------------------------------
  useEffect(() => {
    dispatch(fetchMachines());
  }, [dispatch]);

  const { sessions: filteredSessions } = useFilterSessions(
    sessions?.actual || [],
    selectedFilters
  );
  const [
    machinesWithRecordsIDs,
    statsByMachine,
    namesByMachineID,
    machineKeys
  ] = useMetaGraph(filteredSessions, machines);

  const showSummary = false;

  const handleOnChange = useCallback((type) => {
    setActiveFilter(type);
  }, []);

  const handleSelectedFilters = useCallback((name, values) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: values }));
  }, []);

  return (
    <div>
      <h2>Session Graphs</h2>
      <FiltersBar
        sessions={sessions.data}
        onChange={handleOnChange}
        onSelectedFilters={handleSelectedFilters}
        machines={machines}
        territories={territories}
      />
      {showSummary && (
        <SummaryTable
          machineKeys={machineKeys}
          namesByMachineID={namesByMachineID}
          statsByMachine={statsByMachine}
        />
      )}
      <GridContainer>
        <Row cols={2}>
          <AverageSessions
            sessions={filteredSessions}
            activeFilter={activeFilter}
          />
          <GridContainer>
            <Row cols={2}>
              <AverageTimePerSessions
                sessions={filteredSessions}
                activeFilter={activeFilter}
                selectedFilters={selectedFilters}
                dirtySessions={sessions?.prev || []}
              />
              <SessionsPerState
                sessions={filteredSessions}
                activeFilter={activeFilter}
                selectedFilters={selectedFilters}
                dirtySessions={sessions?.prev || []}
              />
            </Row>
          </GridContainer>
        </Row>
        <Row cols={2}>
          <SessionsByTimeOfDay
            sessions={filteredSessions}
            namesByMachineID={namesByMachineID}
            machinesWithRecordsIDs={machinesWithRecordsIDs}
          />
        </Row>
      </GridContainer>
    </div>
  );
};

export default Sessions;
