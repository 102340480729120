import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 15,
    margin: 10,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    outlineStyle: 'solid',
    outline: '1px #BABABB',
    '&:hover, &:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      outline: '2px #A5BE12',
      outlineStyle: 'solid',
      boxShadow: 0
    }
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: '2px #A5BE12',
    outlineStyle: 'solid',
    boxShadow: 0
  }
}));
const BaseButton = ({ color = 'secondary', className, active, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={`${classes.button} ${className} ${
        active ? classes.active : ''
      }`}
    />
  );
};
export default BaseButton;
