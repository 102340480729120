import { DateTime } from 'luxon';

export const normalizeSessionsResponse = (results = { data: [] }) => ({
  data: results?.data?.models.filter(
    ({ machine_id }) => machine_id !== 'vvTI0R88Ts2j97LyMiQFqA'
  ),
  totalLength: results?.data?.models.length
});

export const namedToOption = ({ id, name }) => ({ value: id, label: name });
export const codeToOption = ({ code, name }) => ({
  value: code,
  label: name
});

export const getDates = ({
  now = DateTime.now().endOf('day'),
  stepInPast = false,
  activeFilter
}) => {
  let past = now;
  const { key, values = [], operation, destinations } = activeFilter;
  if (key === 'date') {
    let start = DateTime.fromMillis(values[1].valueOf());
    past = DateTime.fromMillis(values[0].valueOf());
    if (stepInPast) {
      const diff = start.diff(past, ['days']);
      return [
        past.toJSDate(),
        past.minus({ days: Math.trunc(diff.values.days) }).toJSDate()
      ];
    }

    return [start.toJSDate(), past.toJSDate()];
  }

  switch (operation) {
    case 'minus':
      past = now.minus(destinations);
      break;
    case 'plus':
      past = now.plus(destinations);
      break;
    default:
  }
  return [now.toJSDate(), past.toJSDate()];
};
