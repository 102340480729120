import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { deleteUser, fetchUsers, updateUser } from '../store/slices/users';
import { fetchOrganisations } from '../store/slices/organisations';
import EditUser from './UsersEdit';
import CreateIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Avatar, IconButton } from '@material-ui/core';
import md5 from 'md5';
import ConfirmDialog from '../components/Dialogs/ConfirmDialog';
import {
  selectLoggedInUserName,
  selectLoggedInUserPermissions
} from '../store/slices/auth';

function UserTableRow(props) {
  const { user, organisations, edit, loggedInUserName, confirmDelete } = props;
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      style={{ height: '60px' }}
    >
      <TableCell width={'12px'}>
        <Avatar
          src={`https://www.gravatar.com/avatar/${md5(
            user.email.toLowerCase()
          )}.jpg?d=404`}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {user.name}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.primary_role}</TableCell>
      <TableCell>
        {user.primary_organisation_id
          ? organisations.dataById[user.primary_organisation_id]?.name
          : ''}
      </TableCell>
      <TableCell width={'12px'}>
        <IconButton
          color="primary"
          aria-label="edit"
          onClick={() => {
            edit(user);
          }}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell width={'12px'}>
        {loggedInUserName !== user.name && ( //TODO change this to an ID check
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => confirmDelete(user.id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

function UserTable(props) {
  const loggedInUserPermissions = useSelector(selectLoggedInUserPermissions);
  const loggedInUserName = useSelector(selectLoggedInUserName);
  const { edit, users, organisations, confirmDelete } = props;
  return (
    <TableContainer component={Paper} elevation={9}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width={'12px'} />
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Organisation</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .filter(
              (user) => loggedInUserPermissions.super_admin || !user.super_admin
            ) // only superadmins can see other superadmins
            .map((user) => (
              <UserTableRow
                user={user}
                key={user.id}
                organisations={organisations}
                edit={edit}
                loggedInUserName={loggedInUserName}
                confirmDelete={confirmDelete}
              />
            ))}
        </TableBody>
      </Table>
      <CreateIcon
        aria-label={'new'}
        onClick={() => {
          props.new();
        }}
        style={{ float: 'right' }}
      />
    </TableContainer>
  );
}

const levelmap = {
  none: 0,
  viewer: 1,
  admin: 2,
  organisationadmin: 3,
  superadmin: 4
};
const rt = ['none', 'viewer', 'admin', 'organisationadmin', 'superadmin'];

function decoratePermissions(orglist, level, current) {
  function find_with_default(current, id, level) {
    (current || []).forEach(({ organisation_id, role }) => {
      if (organisation_id === id) {
        level = levelmap[role];
      }
    });
    return level;
  }

  const res = orglist.map((o) => ({
    organisation_id: o.id,
    organisation_name: o.name,
    level: find_with_default(current, o.id, 0)
  }));
  return res;
}

function undecoratePermissions(pl) {
  console.log('undecorating', pl);
  const rv = pl
    .filter(({ level }) => level > 0)
    .map(({ organisation_id, level }) => ({
      organisation_id,
      role: rt[level]
    }));
  console.log('result:', rv);
  return rv;
}

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingUser: undefined,
      deletingUser: undefined,
      confirmOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchOrganisations();
  }

  handleEdit(data) {
    this.setState({
      editingUser: {
        id: data.id,
        email: data.email,
        name: data.name,
        primary_role: data.primary_role,
        super_admin: data.super_admin,
        organisation: data.primary_organisation_id,
        permissions: decoratePermissions(
          this.props.organisations.data,
          0,
          data.permissions
        )
      }
    });
  }

  handleNew() {
    const myOrganisations = this.props.organisations.data; // TODO - replace this with the orgs I can grant access to.
    console.log(
      'in handleNew: editingUser before setstate = ',
      this.state.editingUser
    );
    this.setState({
      editingUser: { permissions: decoratePermissions(myOrganisations, 1, []) }
    });
    console.log(
      'in handleNew: editingUser after setstate = ',
      this.state.editingUser
    );
  }

  updateUserWith(values) {
    this.setState({ editingUser: undefined });
    this.props.updateUser({
      ...values,
      permissions: undecoratePermissions(values.permissions)
    });
  }

  cancelEdit() {
    this.setState({ editingUser: undefined });
  }

  setConfirm(open) {
    this.setState({ confirmOpen: open });
  }

  confirmDelete(userId) {
    this.setState({ confirmOpen: true, deletingUser: userId });
  }

  render() {
    let { tableData, organisations } = this.props;
    let { editingUser } = this.state;

    if (editingUser !== undefined) {
      return (
        <div>
          <h2>Users</h2>
          {
            <EditUser
              initialValues={editingUser}
              onSubmit={(values) => this.updateUserWith(values)}
              onCancel={() => {
                this.cancelEdit();
              }}
            />
          }
        </div>
      );
    } else {
      return (
        <div>
          <h2>Users</h2>
          <UserTable
            users={tableData}
            organisations={organisations}
            edit={(data) => this.handleEdit(data)}
            new={(data) => this.handleNew(data)}
            confirmDelete={(id) => this.confirmDelete(id)}
          />
          <ConfirmDialog
            title="Delete User?"
            open={this.state.confirmOpen}
            setOpen={(open) => this.setConfirm(open)}
            onConfirm={() => {
              this.props.deleteUser(this.state.deletingUser);
            }}
          >
            Are you sure you want to delete this user?
          </ConfirmDialog>
        </div>
      );
    }
  }
}

Users.propTypes = { tableData: PropTypes.any };

function mapStateToProps(state) {
  const { users } = state;
  const { organisations } = state;
  return {
    loading: false, //state.users.busy,
    tableData: users.data,
    organisations,
    totalRecords: users.totalLength,
    limit: 25, //state.users.limit,
    offset: 0 //state.users.offset,
  };
}

// function mapDispatchToProps(dispatch){
//     return {
//         //doFetch: (offset: number, limit: number) => dispatch(Actions.Fetch(offset, limit)),
//         //doSave: (data: TableModel, offset: number, limit: number) => dispatch(Actions.Save(data, offset, limit)),
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Users);
export default connect(mapStateToProps, {
  fetchUsers,
  fetchOrganisations,
  updateUser,
  deleteUser
})(Users);
