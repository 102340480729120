//TODO: will be replace with BE call
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export const useFilterSessions = (
  dirtySessions,
  selectedFilters,
  now = DateTime.now()
) => {
  return useMemo(() => {
    let past = now;
    const { data = [] } = dirtySessions;
    const { machines = [], territories = [] } = selectedFilters;
    const sessions = data
      // eslint-disable-next-line array-callback-return
      .filter(({ machine_id, state_code }) => {
        if (machines.length) {
          if (!machines.some((el) => el === machine_id)) return false;
        }
        //"state_code" property is proposed to be included in session list server response
        if (territories.length) {
          if (!territories.some((el) => el === state_code)) return false;
        }
        return true;
      })
      .sort((a, b) => {
        return DateTime.fromISO(a.created_at) - DateTime.fromISO(b.created_at);
      });
    return { sessions, now, past };
  }, [now, dirtySessions, selectedFilters]);
};
