import { createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../services/AxiosService';
import { selectAuthorizationHeader } from './auth';

const initialState = {
  data: [],
  totalLength: 0
};

export const slice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    updateMachines(state, action) {
      // filter out Brendan's test machine
      state.data = action.payload.models.filter(
        ({ id }) => id !== 'DmVfYm0ARH61i2SCZw7bKw'
      );
      state.totalLength = state.data.length;
    },
    updateMachineRecord(state, action) {
      const machine = action.payload;
      const i = state.data.findIndex((u) => u.id === machine.id);
      if (i >= 0) {
        state.data[i] = machine;
      }
    }
  }
});

export const { updateMachines, updateMachineRecord } = slice.actions;

export const fetchMachines = () => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  const response = await AxiosService.get('/1/machines', {
    headers: authorizationHeader
  }).catch((err) => null);
  if (response) {
    dispatch(updateMachines(response.data));
  }
};

export const patchMachines = (data, id) => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  const response = await AxiosService.patch(`/1/machine/${id}`, data, {
    headers: authorizationHeader
  }).catch((err) => null);
  if (response) {
    dispatch(updateMachineRecord({ ...response.data, id }));
  }
};

export const reducer = slice.reducer;

export function preloadedState() {
  return initialState;
}
