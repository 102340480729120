import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const GridContainer = ({ children }) => {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};
export default GridContainer;

export const Row = ({ cols, children }) => {
  const xs = 12 / cols;
  return !Array.isArray(children) ? (
    <Grid key={`Grid-0`} item xs={xs} spacing={2}>
      {children}
    </Grid>
  ) : (
    children.map((child, key) => (
      <Grid key={`Grid-${key}`} item xs={xs} spacing={2}>
        {child}
      </Grid>
    ))
  );
};

const colStyles = makeStyles(() => ({
  col: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  }
}));
export const Col = ({ children, ...props }) => {
  const classes = colStyles();
  return (
    <div className={classes.col} {...props}>
      {children}
    </div>
  );
};
