import React, { useMemo } from 'react';
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedAreaSeries,
  AnimatedGlyphSeries,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
  buildChartTheme
} from '@visx/xychart';
import { scaleOrdinal } from '@visx/scale';
import { Box, Paper, useTheme } from '@material-ui/core';
import { LegendOrdinal } from '@visx/legend';
import { makeStyles } from '@material-ui/core/styles';
import {
  defaultMargin,
  toKValue,
  tooltipStyles,
  toXCountAccessor,
  toXYChart
} from '../utils';
import { DateTime } from 'luxon';
import { curveNatural } from '@visx/curve';
import { useGraphsMeta } from '../../../hooks/useGraphsMeta';
import { ParentSize } from '@visx/responsive';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  h1: {
    marginTop: 0
  },
  h2: {
    marginBottom: 0
  },
  left: {
    display: 'flex',
    '& > div:first-child': {
      marginLeft: '20px'
    },
    '& > div': {
      margin: '0 5px'
    }
  },
  right: {
    '& > div': {
      alignItems: 'flex-start !important',
      paddingTop: '0.90em'
    }
  }
});

const customTheme = (theme) =>
  buildChartTheme({
    // colors
    colors: [theme.palette.grey['250'], theme.palette.common.green.light]
  });
const accessors = (xAncestorDisplayFormat) => ({
  xAccessor: (d) => {
    return DateTime.fromISO(d.x).toFormat(xAncestorDisplayFormat);
  },
  yAccessor: (d) => d.y
});

const numTicks = 12;
const AverageSessions = ({
  sessions,
  activeFilter,
  margin = defaultMargin
}) => {
  const { xAncestorFormat = 'yyyy-LL-dd', xAncestorDisplayFormat = 'MMM-dd' } =
    activeFilter;
  const theme = useTheme();
  const bottomAxis = theme.palette.grey['250'];
  const bottomAxisTextColor = theme.palette.grey.A200;
  const styles = useStyles();
  const [total, inTotal] = useMemo(() => {
    const inTotal = sessions.reduce(
      (total, next) => toXCountAccessor(total, next, xAncestorFormat),
      {}
    );
    return [Object.entries(inTotal).map(toXYChart), toKValue(sessions.length)];
  }, [sessions, xAncestorFormat]);

  const [average, inAverage] = useGraphsMeta(
    toXCountAccessor,
    sessions,
    xAncestorFormat,
    'month',
    activeFilter
  );

  const legendScale = scaleOrdinal({
    domain: ['Total Sessions', 'Average Sessions'],
    range: [theme.palette.grey['250'], theme.palette.common.green.light]
  });

  return (
    <ParentSize>
      {(parent) => (
        <Paper
          elevation={9}
          style={{
            position: 'relative',
            width: parent.width,
            height: parent.width * 0.5,
            borderRadius: '10px'
          }}
        >
          <Box
            className={styles.box}
            style={{ height: parent.width * 0.5 * 0.25 }}
          >
            <div className={styles.left}>
              <div>
                <Typography variant="h2" className={styles.h2}>
                  Total Sessions
                </Typography>
                <Typography variant="h1" className={styles.h1}>
                  {inTotal}
                </Typography>
              </div>
              <div>
                <Typography variant="h2" className={styles.h2}>
                  Average Sessions
                </Typography>
                <Typography variant="h1" className={styles.h1}>
                  {inAverage}
                </Typography>
              </div>
            </div>
            <LegendOrdinal
              className={styles.right}
              scale={legendScale}
              direction="row"
              labelMargin="0 15px 0 0"
            />
          </Box>
          <XYChart
            height={parent.width * 0.5 * 0.75}
            xScale={{ type: 'band' }}
            yScale={{ type: 'linear' }}
            theme={customTheme(theme)}
            tickFormat={() => {}}
          >
            <AnimatedAxis
              orientation="left"
              numTicks={7}
              stroke={false}
              tickStroke={bottomAxis}
              tickLabelProps={() => ({
                fill: bottomAxisTextColor,
                fontSize: 8,
                fontWeight: 'bold',
                textAnchor: 'end'
              })}
            />
            <AnimatedAxis
              orientation="bottom"
              stroke={false}
              tickStroke={false}
              numTicks={numTicks}
              tickLabelProps={() => ({
                fill: bottomAxisTextColor,
                fontSize: 8,
                fontWeight: 'bold',
                textAnchor: 'middle'
              })}
            />
            <AnimatedGrid
              stroke={bottomAxis}
              columns={false}
              numTicks={numTicks}
              orientation="left"
            />
            <AnimatedAreaSeries
              enableBackground={true}
              stroke={bottomAxis}
              curve={curveNatural}
              color={bottomAxis}
              dataKey="Total Sessions"
              data={total}
              {...accessors(xAncestorDisplayFormat)}
            />
            <AnimatedGlyphSeries
              dataKey="Average Sessions"
              data={average}
              curve={curveNatural}
              {...accessors(xAncestorDisplayFormat)}
            />
            <AnimatedLineSeries
              stroke={theme.palette.common.green.light}
              color={theme.palette.common.green.light}
              dataKey="Average Sessions"
              curve={curveNatural}
              data={average}
              {...accessors(xAncestorDisplayFormat)}
            />
            <Tooltip
              style={tooltipStyles}
              snapTooltipToDatumX
              snapTooltipToDatumY
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) => (
                <div>
                  <div
                    style={{ color: colorScale(tooltipData.nearestDatum.key) }}
                  >
                    {tooltipData.nearestDatum.key}
                  </div>
                  {accessors(xAncestorDisplayFormat).xAccessor(
                    tooltipData.nearestDatum.datum
                  )}
                  {', '}
                  {accessors(xAncestorDisplayFormat).yAccessor(
                    tooltipData.nearestDatum.datum
                  )}
                </div>
              )}
            />
          </XYChart>
          <div
            style={{
              position: 'absolute',
              top: margin.top / 2 - 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: 14
            }}
          >
            <LegendOrdinal
              scale={total}
              direction="row"
              labelMargin="0 15px 0 0"
            />
          </div>
        </Paper>
      )}
    </ParentSize>
  );
};

export default AverageSessions;
