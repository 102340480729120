import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../services/AxiosService';
import { normalizeSessionsResponse } from '../../utils';
import { SESSIONS_FILTERS_DATE } from '../../constants';

export const sessionsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['sessions'],
  endpoints: (build) => ({
    fetchSessionsAll: build.query({
      queryFn: async ({ dates, type }, _queryApi, _extraOptions, baseQuery) => {
        const results = await Promise.all(
          dates.map((date) => {
            return baseQuery({
              url: `/1/sessions/all`,
              params: {
                start_date: date[0],
                end_date: date[1]
              }
            });
          })
        );
        return {
          data: {
            actual: normalizeSessionsResponse(results[0]),
            prev: normalizeSessionsResponse(results[1])
          }
        };
      },
      providesTags: (result, error, arg) => {
        return arg.type !== SESSIONS_FILTERS_DATE.key
          ? [{ type: 'sessions', id: arg.type }]
          : [{ type: 'sessions' }];
      }
    })
  })
});

export const { useFetchSessionsAllQuery, useGetErrorProneQuery } = sessionsApi;
