import { createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../services/AxiosService';
import { selectAuthorizationHeader } from './auth';

const initialState = {
  data: [],
  dataById: {}
};

export const slice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    updateOrganisations(state, action) {
      let data = action.payload.models;
      data.sort((a, b) => a.name.localeCompare(b.name));
      state.data = data;
      state.dataById = data.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
    }
  }
});

export const { updateOrganisations } = slice.actions;

export const fetchOrganisations = () => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  const response = await AxiosService.get('/1/organisations', {
    headers: authorizationHeader
  });
  dispatch(updateOrganisations(response.data));
};

export const selectOrganisationMap = (state) => state.organisations.dataById;
export const selectOrganisations = (state) => state.organisations.data;

export const reducer = slice.reducer;

export function preloadedState() {
  return initialState;
}
