import React, { useRef, memo } from 'react';
import { Paper } from '@material-ui/core';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { LegendOrdinal } from '@visx/legend';
import { localPoint } from '@visx/event';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import { GRAPH_MARGIN, TOOLTIP_STYLES } from '../../constants';
import Typography from '@material-ui/core/Typography';

const getWeekDay = (n) =>
  DateTime.utc({ locale: 'en' }).set({ weekday: n + 1 }).weekdayShort;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    borderRadius: '10px',
    padding: '30px 40px 30px'
  },
  heading: {
    margin: 0,
    padding: '10px 0 20px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey.A200,
    textTransform: 'uppercase'
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 12,
    paddingTop: '10px',
    '& .': {},
    '& .visx-legend-item': {
      '&:not(:last-child) .visx-legend-label': {
        transform: 'translateX(-50%)'
      },
      '&:last-child': {
        width: '0',
        position: 'relative',
        '& > .visx-legend-label': {
          transform: 'none'
        },
        '& > .visx-legend-shape > div': {
          opacity: '0'
        }
      }
    }
  }
}));

const useTooltipStyles = makeStyles({
  title: {
    marginBottom: '10px'
  },
  date: {
    marginRight: '12px'
  },
  session: {
    color: ({ color }) => color
  }
});

const tooltipStyles = {
  ...defaultStyles,
  ...TOOLTIP_STYLES
};

const dateScale = scaleBand({
  domain: Array.from({ length: 24 }, (_, i) => i)
});
const graphScale = scaleLinear({
  domain: [6, -1]
});

const TooltipComponent = ({ day, hour, color, sessions }) => {
  const classes = useTooltipStyles({ color });
  return (
    <div>
      <div className={classes.title}>
        <strong className={classes.date}>{getWeekDay(day)}</strong>
        <strong>
          {hour}.00-{hour + 1}.00
        </strong>
      </div>
      <div className={classes.session}>
        <strong>sessions: {sessions}</strong>
      </div>
    </div>
  );
};

const MemoGraphWrapper = memo(
  ({ children }) => <>{children}</>,
  (p, n) => p.update === n.update
);

const VPBarStackGrid = ({
  width,
  height,
  margin = GRAPH_MARGIN,
  stackData,
  heading = '',
  graphRange
}) => {
  const {
    tooltipOpen,
    tooltipTop,
    tooltipLeft,
    hideTooltip,
    showTooltip,
    tooltipData
  } = useTooltip();

  const ref = useRef();
  const tooltipTimeout = useRef();
  const classes = useStyles();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });

  const realWidth = width;

  const xMax = realWidth - 120;
  const yMax = height - 40;

  dateScale.range([-1, xMax]);
  graphScale.range([yMax, 0]);

  const legendScale = scaleOrdinal({
    domain: Object.keys(graphRange),
    range: Object.values(graphRange)
  });

  const handleHideTooltip = () => {
    tooltipTimeout.current = setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  const handleShowTooltip = (bar, color, i, j) => (event) => {
    clearTimeout(tooltipTimeout.current);
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipData: {
        sessions: bar,
        color,
        day: i,
        hour: j
      },
      tooltipTop: coords.y,
      tooltipLeft: coords.x
    });
  };

  return (
    <Paper ref={ref} elevation={9} className={classes.paper}>
      <MemoGraphWrapper update={[stackData, width]}>
        <Typography variant="h2" className={classes.heading}>
          <span>{heading}</span>
          <span className={classes.subtitle}>timezone of kiosk</span>
        </Typography>
        <svg
          ref={containerRef}
          width={realWidth}
          height={height}
          enableBackground="false"
        >
          <Group left={margin.left}>
            <BarStack data={[]} xScale={dateScale} yScale={graphScale}>
              {() =>
                stackData.map((day, i) => {
                  return day.map((hour, j) => {
                    const color =
                      graphRange[
                        Object.keys(graphRange)
                          .reverse()
                          .find((key) => Math.min(hour, +key) === +key)
                      ];
                    return (
                      <rect
                        key={`bar-stack-${i}-${j}`}
                        x={j * (xMax / 24) + 3}
                        y={i * (yMax / 7) + Math.max(7, yMax / 7 - 10)}
                        height={Math.max(7, yMax / 7 - 10)}
                        width={xMax / 24 - 3}
                        fill={color}
                        onMouseLeave={handleHideTooltip}
                        onMouseMove={handleShowTooltip(hour, color, i, j)}
                      />
                    );
                  });
                })
              }
            </BarStack>
            <AxisBottom
              top={yMax}
              scale={dateScale}
              numTicks={24}
              hideTicks
              hideAxisLine
              tickLabelProps={() => ({
                fill: '#AAAAAA',
                fontSize: 8,
                textAnchor: 'end'
              })}
            />
            <AxisLeft
              numTicks={7}
              scale={graphScale}
              hideTicks
              hideAxisLine
              tickFormat={getWeekDay}
              tickLabelProps={(data) => ({
                fill: '#AAAAAA',
                fontSize: 10,
                verticalAnchor: 'bottom',
                textAnchor: 'start',
                x: '-40px'
              })}
            />
          </Group>
        </svg>
        <div className={classes.legend}>
          <LegendOrdinal
            scale={legendScale}
            direction="row"
            itemDirection="column"
            shapeMargin="0"
            shapeWidth={xMax / 24}
            shapeHeight={yMax / 7 / 3}
            legendLabelProps={{
              style: {
                width: xMax / 24,
                fontSize: 8,
                textAlign: 'center'
              }
            }}
          />
        </div>
      </MemoGraphWrapper>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <TooltipComponent {...tooltipData} />
        </TooltipInPortal>
      )}
    </Paper>
  );
};

export default VPBarStackGrid;
