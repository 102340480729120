import { defaultStyles } from '@visx/tooltip';
import { DateTime } from 'luxon';
//Math
export function min(seq) {
  return seq.reduce((a, b) => (a < b ? a : b), []);
}
export function max(seq) {
  return seq.reduce((a, b) => (a > b ? a : b), []);
}
export function diff(a, b, fixed = 1) {
  return (100 * Math.abs((a - b) / (a + b) || 0 / 2)).toFixed(fixed);
}

export const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white'
};
export const bottomAxis = '#aaa';

//mappers
export const toXYChart = ([key, value]) => ({
  x: key,
  y: !isNaN(value) ? parseFloat(value) : 0
});

export const toXCountAccessor = (total, next, format) => {
  const { start_time } = next;
  const created = DateTime.fromSeconds(start_time).toFormat(format);
  total[created] = total[created] ? total[created] + 1 : 1;
  return total;
};

export const toXDurationAccessor = (total, next, format) => {
  const { end_time = null, start_time } = next;
  // skip if sessions is not completed
  if (!end_time) return total;
  const created = DateTime.fromSeconds(start_time).toFormat(format);
  const duration = end_time - start_time;
  const minutes = Math.floor(duration / 60);
  total[created] = total[created]
    ? Math.floor((total[created] + minutes) / 2)
    : minutes;
  return total;
};

export const toMonthValues = (total, next) => {
  const created_at = DateTime.fromISO(next.created_at).toFormat('yyyy-LL');
  total[created_at] = total[created_at] ? total[created_at] + 1 : 1;
  return total;
};

export const toMonthTime = (total, next) => {
  const { end_time, start_time, created_at } = next;
  const created = DateTime.fromISO(created_at).toFormat('yyyy-LL');
  const duration = end_time - start_time;
  const minutes = Math.floor(duration / 60);
  total[created] = total[created] ? total[created] + minutes : minutes;
  return total;
};

//beautify value
export const toKValue = (value) => {
  return value >= 1000 ? (value / 1000).toFixed(1) + 'K' : value;
};
