import React from 'react'

const LoginContext = React.createContext({})

export function LoginContextProvider(props) {
    const {user} = props;
    return (
        <LoginContext.Provider value={{user}}>
            {props.children}
        </LoginContext.Provider>
    )
}

// https://medium.com/analytics-vidhya/react-how-to-use-context-with-functional-components-240a8ec4126a

export const useLoginContext = () => React.useContext(LoginContext);