import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
// eslint-disable-next-line no-unused-vars
import { makeValidate, showErrorOnBlur, TextField } from 'mui-rff';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form } from 'react-final-form';
import { connect, useSelector } from 'react-redux';

import { login, selectLoggedInUserName } from '../store/slices/auth';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function SignIn(props) {
  const classes = useStyles();

  const loggedInUserName = useSelector(selectLoggedInUserName);
  const history = useHistory();
  const loggedIn = !!loggedInUserName;

  useState(() => {
    if (loggedIn) {
      history.push('/machines');
    }
  }, [loggedIn]);

  const onSubmit = (values) => {
    props.login(values.email, values.password);
    history.push('/'); //this should depend on success??
  };

  const initialValues = {
    email: '',
    password: ''
    // remember_me: false,
  };

  const validateSchema = makeValidate(
    Yup.object().shape({
      email: Yup.string().email(),
      password: Yup.string().required()
      // remember_me: Yup.boolean(),
    })
  );

  // const loggedIn = useLoginContext();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {/*<div>{loggedIn.user.name}</div>*/}
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in to Viewport Analytics
        </Typography>

        <Form
          onSubmit={onSubmit}
          validate={validateSchema}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                showError={showErrorOnBlur}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/*<Grid container>*/}
              {/*    <Grid item xs>*/}
              {/*        <Checkboxes*/}
              {/*            name="remember_me" color="primary"*/}
              {/*            data={{label: 'Remember me' }}*/}
              {/*        />*/}
              {/*    </Grid>*/}
              {/*    <Grid item xs>*/}
              {/*        <Link href="#" variant="body2">*/}
              {/*            Forgot password?*/}
              {/*        </Link>*/}
              {/*    </Grid>*/}
              {/*</Grid>*/}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={submitting || pristine}
              >
                Sign In
              </Button>
            </form>
          )}
        />
      </div>
    </Container>
  );
}

export default connect(null, { login })(SignIn);
//export default SignIn;
