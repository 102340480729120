import { DateTime } from 'luxon';

export const SESSIONS_FILTERS = [
  {
    key: 'past_year',
    title: 'Past Year',
    operation: 'minus',
    xAncestorFormat: 'yyyy-LL',
    xAncestorDisplayFormat: 'yyyy-MMM',
    destinations: {
      years: 1
    },
    emptyYAccessorTicks: () => 12 //months
  },
  {
    key: 'past_quarter',
    title: 'Past Quarter',
    xAncestorFormat: 'yyyy-LL',
    xAncestorDisplayFormat: 'yyyy-MMM',
    operation: 'minus',
    destinations: {
      month: 3
    },
    emptyYAccessorTicks: () => 12 // weeks  (4w * 3m)
  },
  {
    key: 'past_month',
    title: 'Past Month',
    operation: 'minus',
    xAncestorFormat: 'yyyy-LL-dd',
    xAncestorDisplayFormat: 'MMM-dd',
    destinations: {
      month: 1
    },
    emptyYAccessorTicks: () => 30 //days
  },
  {
    key: 'past_week',
    title: ' Past Week',
    xAncestorFormat: 'yyyy-LL-dd',
    xAncestorDisplayFormat: 'MMM-dd',
    operation: 'minus',
    destinations: {
      weeks: 1
    },
    emptyYAccessorTicks: () => 7 //days
  }
];
export const SESSIONS_FILTERS_DATE = {
  key: 'date',
  title: ' Selected Date',
  value: null,
  emptyYAccessorTicks: (values = []) => {
    const diff = DateTime.fromMillis(values[1].valueOf()).diff(
      DateTime.fromMillis(values[0].valueOf()),
      ['days']
    );
    return Math.trunc(diff.values.days);
  }
};

export const GRAPH_MARGIN = { top: 40, right: 40, bottom: 40, left: 40 };

export const TOOLTIP_STYLES = {
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: '#FFFFFF'
};

//mock data to be replaced with redux data & BE
export const territories = {
  data: [
    { id: '0', code: 'NSW', name: 'New South Wales' },
    { id: '1', code: 'QLD', name: 'Queensland' },
    { id: '2', code: 'SA', name: 'South Australia' },
    // { id: '3', code: 'TAN', name: 'Tasmania' },
    { id: '4', code: 'VIC', name: 'Victoria' },
    { id: '5', code: 'WA', name: 'Western Australia' },
    { id: '6', code: 'ACT', name: 'Australian Capital Territory' }
    // { id: '7', code: 'JBT', name: 'Jervis Bay Territory' },
    // { id: '8', code: 'NT', name: 'Northern Territory' },
    // { id: '9', code: 'ACI', name: 'Ashmore and Cartier Islands' },
    // { id: '10', code: 'AAT', name: 'Australian Antarctic Territory' },
    // { id: '11', code: 'CI', name: 'Christmas Island' },
    // { id: '12', code: 'CIK', name: 'Cocos (Keeling) Islands' },
    // { id: '13', code: 'CSI', name: 'Coral Sea Islands' },
    // { id: '14', code: 'HIMI', name: 'Heard Island and McDonald Islands' },
    // { id: '15', code: 'NI', name: 'Norfolk Island' }
  ]
};
