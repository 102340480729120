import { createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../services/AxiosService';
import { selectAuthorizationHeader } from './auth';
const pageOptions = {
  page: 1,
  page_size: 100
};
const initialState = {
  data: [],
  totalLength: 0
};

export const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    updateSessions(state, action) {
      if (action.payload) {
        // filter out Brendan's test machine
        state.data = action.payload.models.filter(
          ({ machine_id }) => machine_id !== 'vvTI0R88Ts2j97LyMiQFqA'
        );
        state.totalLength = state.data.length;
      }
    }
  }
});

export const { updateSessions } = slice.actions;

export const fetchSessions =
  (start = null, end = null) =>
  async (dispatch, getState) => {
    const authorizationHeader = selectAuthorizationHeader(getState());
    const response = await AxiosService.get('/1/sessions', {
      headers: authorizationHeader,
      params: {
        ...pageOptions,
        start_date: start,
        end_date: end
      }
    });
    dispatch(updateSessions(response.data));
  };

export const fetchSessionsAll =
  (start = null, end = null) =>
  async (dispatch, getState) => {
    const authorizationHeader = selectAuthorizationHeader(getState());
    const response = await AxiosService.get('/1/sessions/all', {
      headers: authorizationHeader,
      params: {
        start_date: start,
        end_date: end
      }
    });
    dispatch(updateSessions(response.data));
  };

export const reducer = slice.reducer;

export function preloadedState() {
  return initialState;
}
