import React from 'react';
import {
  AnimatedAxis,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
  buildChartTheme
} from '@visx/xychart';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { diff, tooltipStyles, toXDurationAccessor } from '../utils';
import { DateTime } from 'luxon';
import { curveNatural } from '@visx/curve';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { ParentSize } from '@visx/responsive';
import { useGraphsMeta } from '../../../hooks/useGraphsMeta';
import { useFilterSessions } from '../../../hooks/useFilterSessions';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  h1: {
    marginTop: 0,
    marginBottom: 0
  },
  h2: {
    marginBottom: 0
  },
  sub: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    color: ({ upDown }) =>
      upDown ? theme.palette.common.green.light : theme.palette.common.red,
    '& > span': {
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.grey.A200
    }
  },
  left: {
    display: 'flex',
    '& > div': {
      margin: '0 20px'
    }
  },
  right: {
    '& > div': {
      alignItems: 'flex-start !important',
      paddingTop: 20
    }
  }
}));

const customTheme = (theme) =>
  buildChartTheme({
    // colors
    colors: [theme.palette.common.green.dark, theme.palette.common.green.light] // categorical colors, mapped to series via `dataKey`s
  });

const accessors = (xAncestorDisplayFormat) => ({
  xAccessor: (d) => {
    return DateTime.fromISO(d.x).toFormat(xAncestorDisplayFormat);
  },
  yAccessor: (d) => d.y
});

const numTicks = 5;

const AverageTimePerSessions = ({
  sessions = [],
  activeFilter,
  selectedFilters,
  dirtySessions = []
}) => {
  const {
    xAncestorFormat = 'yyyy-LL-dd',
    xAncestorDisplayFormat = 'yyyy-LL-dd'
  } = activeFilter;

  const { sessions: pastSessions } = useFilterSessions(
    dirtySessions,
    selectedFilters
  );

  const theme = useTheme();
  const [, pastPeriodPercentage] = useGraphsMeta(
    toXDurationAccessor,
    pastSessions,
    xAncestorFormat,
    null,
    activeFilter
  );
  const [average, inAverage] = useGraphsMeta(
    toXDurationAccessor,
    sessions,
    xAncestorFormat,
    null,
    activeFilter
  );
  const upDown = !(pastPeriodPercentage > inAverage);
  const percentage = diff(pastPeriodPercentage, inAverage);
  const styles = useStyles({ upDown });

  return (
    <ParentSize>
      {(parent) => (
        <Paper
          elevation={9}
          style={{
            position: 'relative',
            width: parent.width,
            height: parent.width + 8,
            borderRadius: '10px'
          }}
        >
          <Box
            className={styles.box}
            style={{ height: (parent.width + 8) * 0.35 }}
          >
            <div className={styles.left}>
              <div>
                <Typography variant="h2" className={styles.h2}>
                  AVG Time per session
                </Typography>
                <Typography variant="h1" className={styles.h1}>
                  {inAverage} min
                </Typography>
                <Typography variant="h2" className={styles.sub}>
                  {percentage}%{' '}
                  {upDown ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                  <span>from last period</span>
                </Typography>
              </div>
            </div>
          </Box>

          <XYChart
            width={parent.width}
            height={(parent.width + 8) * 0.7}
            xScale={{ type: 'band' }}
            yScale={{ type: 'linear' }}
            theme={customTheme(theme)}
            tickFormat={() => {}}
          >
            <AnimatedAxis
              orientation="left"
              numTicks={numTicks}
              stroke={false}
              tickStroke={false}
              tickLabelProps={() => ({
                fill: theme.palette.grey.A200,
                fontSize: 8,
                fontWeight: 'bold',
                textAnchor: 'end'
              })}
            />
            <AnimatedAreaSeries
              enableBackground={true}
              stroke={theme.palette.grey[200]}
              color={theme.palette.grey[200]}
              curve={curveNatural}
              dataKey="Total"
              data={average}
              {...accessors(xAncestorDisplayFormat)}
            />
            <AnimatedLineSeries
              curve={curveNatural}
              dataKey="Average"
              data={average}
              {...accessors(xAncestorDisplayFormat)}
            />
            <Tooltip
              style={tooltipStyles}
              snapTooltipToDatumX
              snapTooltipToDatumY
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) => (
                <div>
                  <div
                    style={{
                      color: colorScale(tooltipData.nearestDatum.key)
                    }}
                  >
                    {tooltipData.nearestDatum.key}
                  </div>
                  {accessors(xAncestorDisplayFormat).xAccessor(
                    tooltipData.nearestDatum.datum
                  )}
                  {', '}
                  {accessors(xAncestorDisplayFormat).yAccessor(
                    tooltipData.nearestDatum.datum
                  )}
                </div>
              )}
            />
          </XYChart>
        </Paper>
      )}
    </ParentSize>
  );
};

export default AverageTimePerSessions;
