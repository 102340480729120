import { useMemo } from 'react';
import VPBarStackGrid from '../VPBarStackGrid';
import { ParentSize } from '@visx/responsive';

const makeRange = (data) => {
  const flatStack = [...new Set(data.flat())].sort((a, b) => a - b);
  const min = flatStack[0];
  let max = flatStack[flatStack.length - 1];

  if (max - min < 5) max = (min + 1) * 5;

  const quarter = Math.round((max - min) / 2.5);

  return {
    [min]: '#eeeeee',
    [quarter]: '#e0f098',
    [quarter * 2]: '#c2e030',
    [quarter * 3]: '#90a820',
    [max]: '#90a820'
  };
};

const SessionsByTimeOfDay = (props) => {
  const { sessions, machinesWithRecordsIDs } = props;
  const heading = 'Sessions by time of day';

  const { stackData, range } = useMemo(() => {
    const stack = Array.from({ length: 7 }, () => new Array(24).fill(0));

    sessions.forEach(({ created_at, start_time, end_time }) => {
      const date = new Date(created_at);
      const hour = date.getHours();
      const day = date.getDay();

      const difHours = end_time
        ? (end_time - start_time) / (1000 * 60 * 60)
        : 0;
      let dayDif = day;
      let hourDif = hour;
      for (let i = 0; i <= difHours; i++) {
        if (hourDif > 23) {
          hourDif = 0;
          dayDif++;
        }

        if (dayDif > 6) dayDif = 0;
        stack[dayDif][hourDif] += 1;

        hourDif++;
      }
    });

    return { stackData: stack, range: makeRange(stack) };
  }, [sessions]);

  return (
    <ParentSize>
      {(parent) => (
        <VPBarStackGrid
          width={parent.width}
          height={parent.width * 0.25}
          stackData={stackData}
          stackKeys={machinesWithRecordsIDs}
          heading={heading}
          graphRange={range}
        />
      )}
    </ParentSize>
  );
};

export default SessionsByTimeOfDay;
