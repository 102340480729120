import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import BaseButton from '../Button/BaseButton';
import { useToggle } from 'react-use';
import _uniqueId from 'lodash/uniqueId';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '7px 16px',
    minWidth: '170px'
  },
  select: {
    zIndex: -1,
    width: 0,
    height: 0,
    display: 'block'
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: '2px #A5BE12',
    outlineStyle: 'solid',
    boxShadow: 0
  }
}));
const MenuProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      borderRadius: 15,
      marginLeft: 10,
      width: 'auto',
      minWidth: '170px'
    }
  }
};
const ButtonSelect = ({
  color = 'secondary',
  multiple = false,
  options,
  className = '',
  onHide,
  title,
  name,
  simpleTitle = false,
  ...rest
}) => {
  const labelId = _uniqueId();
  const [open, toggleOpen] = useToggle(false);
  const classes = useStyles();
  const [selectedOptions, setSelectedKOptions] = useState([]);
  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedKOptions(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClose = useCallback(() => {
    toggleOpen();
    setActive(selectedOptions.length !== 0);
    onHide && onHide(name, selectedOptions);
  }, [onHide, name, selectedOptions, toggleOpen]);

  return (
    <Box {...rest}>
      <BaseButton
        active={active}
        className={classes.button}
        onClick={toggleOpen}
      >
        {!simpleTitle ? `${selectedOptions.length} ${title} Selected` : title}
      </BaseButton>
      <InputLabel id={labelId} />
      <Select
        MenuProps={MenuProps}
        className={classes.select}
        labelId={labelId}
        multiple
        open={open}
        onClose={handleClose}
        onOpen={toggleOpen}
        value={selectedOptions}
        onChange={handleChange}
      >
        {options.map(({ label, value }, i) => (
          <MenuItem key={i} value={value}>
            <Checkbox checked={selectedOptions.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
export default ButtonSelect;
