// Picker
import React from 'react';
import {Field, Form} from 'react-final-form';
import {Checkboxes, TextField,} from 'mui-rff';
import {Box, Button, CssBaseline, Grid, Paper,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {connect, useSelector} from "react-redux/";
import {fetchUser} from "../store/slices/users";
import {selectLoggedInUserID, selectLoggedInUserPermissions} from "../store/slices/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
    },
    margin: {
        height: theme.spacing(3),
    },
    permissionSection: {}
}));

const permissionLevels = [
    'None',
    'Viewer',
    'Admin',
    'Org Admin',
];


function PermissionSlider(props) {
    const classes = useStyles();
    const {label, index, disabled, upperLimit} = props
    const marks = permissionLevels.map((label, value) => ({value, label}));

    return (<Grid item xs={12} container className={classes.permissionSection}>
            <Grid item xs={3}>
                <Typography id="discrete-slider-custom" gutterBottom>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Field
                    name={`permissions[${index}].level`}
                    render={({input: {name, value, onChange, checked, ...restInput}}) => (
                        <Slider
                            name={name}
                            value={value}
                            onChange={(e, value) => {
                                onChange(value>upperLimit?upperLimit:value)
                            }}
                            step={null}
                            valueLabelDisplay="off"
                            marks={marks}
                            max={3}
                            disabled={disabled}
                            {...restInput}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}


const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.email) {
        errors.email = 'Required';
    }
    if (values.new_password !== values.password_confirmation) {
        errors.new_password = 'Does not match confirmation';
    }
    if (!values.id && !values.new_password) {
        errors.new_password = "password required for new users"
    }
    return errors;
};

function PermissionsGroup({loggedInUserPermissions, permissions, itme}) {
    function getUpperLimitForOrg(id) {
        const {level_by_org, super_admin} = loggedInUserPermissions;
        if (super_admin) {
            return 3
        } else if (id in level_by_org) {
            return level_by_org[id]
        }
        else {
            return 0
        }
    }
    return (
        <Box width="100%" style={{border: "1px solid #ccc", borderRadius: "4px", padding: "5px"}}>
            {permissions.map((o, index) => {
                const myLevel = getUpperLimitForOrg(o.organisation_id)
                var disabled = itme;
                var upperLimit=3;
                console.log("myLevel",myLevel,"o.level",o.level,"orgname",o.organization_name)
                if (myLevel<o.level) {
                    disabled=true
                }
                else {
                    upperLimit=myLevel
                }
                return (
                    <PermissionSlider
                        id={o.organisation_id}
                        label={o.organisation_name}
                        initialValue={o.level}
                        index={index}
                        disabled={disabled}
                        upperLimit={upperLimit}
                    />
                );
            })}
        </Box>)
}

function EditUser(props) {
    let {onSubmit, initialValues} = props;

    const loggedInUserPermissions = useSelector(selectLoggedInUserPermissions)
    const loggedInUserID = useSelector(selectLoggedInUserID)
    const itme = loggedInUserID === initialValues.id

    console.log("liup=", loggedInUserPermissions)
    console.log("initialVlaues =", initialValues)

    return (
        <div style={{padding: 16, margin: 'auto', maxWidth: 600}}>
            <CssBaseline/>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Paper style={{padding: 40}} elevation={9}>
                            <Grid container alignItems="flex-start" spacing={2}>

                                <Grid item xs={12}>
                                    <TextField variant="outlined" label="Name" name="name" margin="none"
                                               required={true}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="outlined" type="email" label="Email" name="email"
                                               margin="none"
                                               required={true}/>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField variant="outlined" margin="normal"
                                               name="new_password"
                                               label="Password"
                                               type="password"
                                               autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField variant="outlined" margin="normal"
                                               name="password_confirmation"
                                               label="Password confirmation"
                                               type="password"
                                               autoComplete=""
                                    />
                                </Grid>
                                <Grid item xs={12} container>
                                    {(loggedInUserPermissions.super_admin) &&  // only a superadmin can promote others to superadmin
                                    <Grid item xs={12}>
                                        <Checkboxes
                                            name="super_admin"
                                            formControlProps={{margin: 'none'}}
                                            disabled={itme}  // superadmins can't demote themselves
                                            data={{label: 'Superadmin', value: true}}
                                        />
                                    </Grid>
                                    }

                                    {(!values.super_admin) &&  //super admins can already access everything
                                    <PermissionsGroup loggedInUserPermissions={loggedInUserPermissions} permissions={values.permissions} itme={itme}/>
                                    }
                                </Grid>

                                {/*<Grid item style={{marginTop: 16}}>*/}
                                {/*    <Button type="button" variant="contained" onClick={form.reset} disabled={submitting || pristine} >*/}
                                {/*        Reset*/}
                                {/*    </Button>*/}
                                {/*</Grid>*/}
                                <Grid item style={{marginTop: 16}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item style={{marginTop: 16}}>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={() => props.onCancel()}
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                    </form>
                )}
            />
        </div>
    );
}

export default connect(null, {fetchUser})(EditUser);