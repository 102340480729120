import React, { useMemo, useRef } from 'react';
import { CustomProjection } from '@visx/geo';
import topology from './../../../utils/australia-topo.json';
import * as topojson from 'topojson-client';
import { geoMercator } from 'd3-geo';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { diff, tooltipStyles, toXDurationAccessor } from '../utils';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { ParentSize } from '@visx/responsive';
import Typography from '@material-ui/core/Typography';
import { useFilterSessions } from '../../../hooks/useFilterSessions';
import { territories } from '../../../constants';
import { useGraphsMeta } from '../../../hooks/useGraphsMeta';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  h1: {
    marginTop: 0,
    marginBottom: 0
  },
  h2: {
    marginBottom: 0
  },
  sub: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    color: ({ upDown }) =>
      upDown ? theme.palette.common.green.light : theme.palette.common.red,
    '& > span': {
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.grey.A200
    }
  },
  left: {
    display: 'flex',
    '& > div': {
      margin: '0 20px'
    }
  },
  right: {
    '& > div': {
      alignItems: 'flex-start !important',
      paddingTop: 20
    }
  }
}));

const { features: australianStates } = topojson.feature(
  topology,
  topology.objects.states
);

function range(start, end, step = 1) {
  const len = Math.floor((end - start) / step) + 1;
  return len > 0
    ? Array(len)
        .fill()
        .map((_, idx) => start + idx * step)
    : Array(step);
}

const colors = ['#eeeeee', '#e0f098', '#c2e030', '#90a820'];
const SessionsPerState = ({
  sessions = [],
  selectedFilters,
  activeFilter,
  dirtySessions = []
}) => {
  const tooltipTimeout = useRef();
  const stateData = useMemo(() => {
    const data = australianStates.map((data) => {
      const item = territories.data.find(
        ({ name }) => data.properties.name === name
      );
      return sessions.filter(({ state_code }) => state_code === item?.code);
    });
    const min = Math.min.apply(
        null,
        data.map((item) => item.length)
      ),
      max = Math.max.apply(
        null,
        data.map((item) => item.length)
      );
    const colorRange = range(min, max, max / colors.length + 1);
    return data.map((value) => {
      const idx = colorRange.indexOf(
        Math.max.apply(
          Math,
          colorRange.filter((r, index) => {
            return value.length >= r;
          })
        )
      );
      const calculatedIndex = value.length > 0 && idx === 0 ? 1 : idx;
      return { color: colors[calculatedIndex], sessions: value.length };
    });
  }, [sessions]);
  const { xAncestorFormat = 'yyyy-LL-dd' } = activeFilter;
  const { sessions: pastSessions } = useFilterSessions(
    dirtySessions,
    selectedFilters
  );
  const {
    tooltipOpen,
    tooltipTop,
    tooltipLeft,
    hideTooltip,
    showTooltip,
    tooltipData
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });

  const [, pastPeriodPercentage] = useGraphsMeta(
    toXDurationAccessor,
    pastSessions,
    xAncestorFormat,
    null,
    activeFilter
  );
  const [, inAverage] = useGraphsMeta(
    toXDurationAccessor,
    sessions,
    xAncestorFormat,
    null,
    activeFilter
  );
  const upDown = !(pastPeriodPercentage > inAverage);
  const percentage = diff(pastPeriodPercentage, inAverage);

  const styles = useStyles({ upDown });

  return (
    <ParentSize>
      {(parent) => (
        <Paper
          elevation={9}
          style={{
            position: 'relative',
            width: parent.width,
            height: parent.width + 8,
            borderRadius: '10px'
          }}
        >
          <Box
            className={styles.box}
            style={{ height: (parent.width + 8) * 0.35 }}
          >
            <div className={styles.left}>
              <div>
                <Typography variant="h2" className={styles.h2}>
                  Sessions Per State
                </Typography>
                <Typography variant="h1" className={styles.h1}>
                  Australia
                </Typography>
                <Typography variant="h2" className={styles.sub}>
                  {percentage}%{' '}
                  {upDown ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                  <span>then last year</span>
                </Typography>
              </div>
            </div>
          </Box>
          <svg
            width={parent.width}
            height={(parent.width + 8) * 0.65}
            style={{ borderRadius: '14px' }}
            ref={containerRef}
          >
            <CustomProjection
              projection={geoMercator}
              data={australianStates}
              scale={(parent.width + parent.width * 0.75) / 1.8}
              rotate={[-132, 0]}
              center={[2, -37]}
              translate={[parent.width / 2, (parent.width * 0.95) / 2]}
            >
              {(customProjection) => (
                <g>
                  {customProjection.features.map(({ feature, path }, i) => (
                    <path
                      key={`map-feature-${i}`}
                      d={path || ''}
                      fill={stateData[i].color}
                      stroke="#fff"
                      onMouseLeave={() => {
                        tooltipTimeout.current = setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={(event) => {
                        clearTimeout(tooltipTimeout.current);
                        const coords = localPoint(
                          event.target.ownerSVGElement,
                          event
                        );
                        showTooltip({
                          tooltipData: {
                            name: feature.properties.name,
                            sessions: stateData[i].sessions,
                            color: stateData[i].color
                          },
                          tooltipTop: coords.y,
                          tooltipLeft: coords.x
                        });
                      }}
                    />
                  ))}
                </g>
              )}
            </CustomProjection>
          </svg>
          {tooltipOpen && tooltipData && (
            <TooltipInPortal
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div style={{ color: '#fff' }}>
                <strong>{tooltipData.name}</strong>
              </div>
              <div style={{ color: tooltipData.color }}>
                {tooltipData.sessions} sessions
              </div>
            </TooltipInPortal>
          )}
        </Paper>
      )}
    </ParentSize>
  );
};

export default SessionsPerState;
