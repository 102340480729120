import { useMemo } from 'react';
import { toKValue, toXYChart } from '../components/VPBarStack/utils';
import { DateTime } from 'luxon';

const defValue = (format = 'yyyy', activeFilter) => {
  const defaultTicks = 12;
  const { emptyYAccessorTicks, values = [] } = activeFilter;
  return [
    ...Array(
      typeof emptyYAccessorTicks === 'function'
        ? emptyYAccessorTicks(values)
        : defaultTicks
    )
  ]
    .map((_, index) => {
      const monthName = DateTime.fromFormat(
        `${DateTime.now()
          .minus({ days: index - 1 })
          .toFormat(format)}`,
        format
      );
      return { x: monthName, y: 0 };
    })
    .reverse();
};

export const useGraphsMeta = (
  fn,
  sessions = [],
  xAncestorFormat = 'yyyy-LL-dd',
  inRangeUnit = null,
  activeFilter
) => {
  return useMemo(() => {
    const inTotal = sessions.reduce(
      (total, next) => fn(total, next, xAncestorFormat),
      {}
    );
    if (inRangeUnit !== null) {
      Object.keys(inTotal).forEach((key) => {
        const days = DateTime.fromISO(key).endOf(inRangeUnit).day;
        inTotal[key] = (inTotal[key] / days).toFixed(1);
      });
    }
    const inAverage = toKValue(
      (
        Object.values(inTotal).reduce(
          (acc, item) => parseFloat(acc) + parseFloat(item),
          0
        ) / Object.keys(inTotal).length || 0
      ).toFixed(1)
    );

    const result = Object.entries(inTotal).map(toXYChart);
    return [
      result.length ? result : defValue(xAncestorFormat, activeFilter),
      parseFloat(inAverage)
    ];
  }, [activeFilter, fn, inRangeUnit, sessions, xAncestorFormat]);
};
