import { useMemo } from 'react';

export function useMetaGraph(sessions = [], machines = []) {
  const [
    machinesWithRecordsIDs = [],
    statsByMachine = {},
    namesByMachineID = {},
    machineKeys = []
  ] = useMemo(() => {
    const statsByMachine = {};
    const namesByMachineID = {};
    const machineKeys = [];
    if (!sessions.length || !machines.totalLength) return [[], {}, {}, {}];

    machines.data.forEach(({ id, name }) => {
      statsByMachine[id] = {
        count: 0,
        earliest: null,
        latest: null
      };
      namesByMachineID[id] = name;
      machineKeys.push(id);
    });

    sessions.forEach(({ machine_id }) => {
      if (!(machine_id in statsByMachine)) {
        statsByMachine[machine_id] = {
          count: 0,
          earliest: null,
          latest: null
        };
        namesByMachineID[machine_id] = machine_id;
        machineKeys.push(machine_id);
        console.log('adding unknown machine with id', machine_id);
      }
    });

    sessions.forEach(({ start_time, machine_id }) => {
      statsByMachine[machine_id].count += 1;
      statsByMachine[machine_id].earliest = Math.min(
        statsByMachine[machine_id].earliest || start_time,
        start_time
      );
      statsByMachine[machine_id].latest = Math.max(
        statsByMachine[machine_id].latest || start_time,
        start_time
      );
    });
    const machinesWithRecordsIDs = machineKeys.filter(
      (mk) => statsByMachine[mk].count > 0
    );

    return [
      machinesWithRecordsIDs,
      statsByMachine,
      namesByMachineID,
      machineKeys
    ];
  }, [machines, sessions]);

  return [
    machinesWithRecordsIDs,
    statsByMachine,
    namesByMachineID,
    machineKeys
  ];
}
