import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../services/AxiosService';
import md5 from 'md5';
import { fetchUsers } from './users';
import { Interval } from 'luxon';

const initialState = {
  expiry: null,
  sessionID: null,
  userID: null,
  name: null,
  email: null,
  super_admin: false,
  permissions: []
};

// eslint-disable-next-line
const sessionsAdapter = createEntityAdapter({
  selectId: (session) => session.guid,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const localStorageMapping = Object.freeze({
  expiry: 'sessionExpiry',
  sessionID: 'sessionID',
  userID: 'userID',
  email: 'userEmail',
  name: 'userName',
  super_admin: 'permissionSuperAdmin',
  permissions: 'permissionSet'
});

function setLocalStorage(state) {
  // bail if browser doesn't support local storage. What!?
  if (localStorage == null) {
    return;
  }

  Object.keys(state).forEach((key) => {
    if (state[key] != null)
      localStorage.setItem(localStorageMapping[key], state[key]);
  });
}

function clearLocalStorage() {
  if (localStorage == null) return;
  Object.values(localStorageMapping).forEach((value) => {
    localStorage.removeItem(value);
  });
}

function getData() {
  if (localStorage == null) return null;
  const data = Object.assign({}, initialState);

  let valid = true;

  Object.keys(localStorageMapping).forEach((key) => {
    data[key] = localStorage.getItem(localStorageMapping[key]);
    if (data[key] == null) valid = false;
  });

  if (!Array.isArray(data.permissions)) data.permissions = [];

  if (!valid) return initialState;
  return data;
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuth(state, action) {
      const { expiry, sessionID, userID, name, email } = action.payload;
      state.expiry = expiry;
      state.sessionID = sessionID;
      state.userID = userID;
      state.name = name;
      state.email = email;
      setLocalStorage(state);
    },
    updateExpiry(state, action) {
      state.expiry = action.payload;
      setLocalStorage(state);
    },
    updateAuthWithNewUserData(state, action) {
      const authorised_user = action.payload;
      console.log('authorized user is', authorised_user);
      state.super_admin = authorised_user.super_admin;
      state.permissions = authorised_user.permissions;
      setLocalStorage(state);
    },
    loggedOut(state, action) {
      state.name = null;
      state.email = null;
      state.sessionID = null;
      state.expiry = null;
      clearLocalStorage();
    }
  }
});

export const { updateAuth, updateExpiry, updateAuthWithNewUserData } =
  slice.actions;

export const login = (email, password) => async (dispatch, getState) => {
  const response = await AxiosService.post('/1/login', {
    email,
    password
  }).catch((err) => {
    return err.response;
  });
  if (response && response.status === 200) {
    dispatch(updateAuth({ email, ...response.data }));
    //dispatch(keepAlive(response.data.expiry, response.data.sessionID))
    //  ^ don't need this, as the App component will kick one off when loggedIn becomes true
    dispatch(fetchUsers()); // this will then trigger an updateAuthPermissions
  }
  // else dispatch an update to indicate error condition
};

export const keepCurrentSessionAlive = () => async (dispatch, getState) => {
  const { expiry, sessionID } = getState().auth;
  dispatch(keepAlive(expiry, sessionID));
};

const keepAlive = (expiry, sessionID) => async (dispatch, getState) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const interval = Interval.fromDateTimes(new Date(), new Date(expiry));
  await delay(interval.toDuration().milliseconds * 0.8);

  const state = getState();
  if (state.auth.sessionID === sessionID) {
    const authorizationHeader = selectAuthorizationHeader(getState());
    const response = await AxiosService.get('/1/auth/keepalive', {
      headers: authorizationHeader
    }).catch((err) => {
      console.log('keepalive received error ', JSON.stringify(err));
      return err.response;
    });
    if (response && response.status === 200) {
      const { expiry } = response.data;
      dispatch(updateExpiry(expiry));
      dispatch(keepAlive(expiry, sessionID));
    } else {
      dispatch(slice.actions.loggedOut());
    }
  }
};

export const logout = () => async (dispatch, getState) => {
  const authorizationHeader = selectAuthorizationHeader(getState());
  const response = await AxiosService.post('/1/auth/logout', null, {
    headers: authorizationHeader
  }).catch((err) => {
    return err.response;
  });
  console.log('logout received response:', response);
  dispatch(slice.actions.loggedOut());
};

const levelmap = {
  // TODO - DRY this, it's also present in Users.js
  none: 0,
  viewer: 1,
  admin: 2,
  organisationadmin: 3,
  superadmin: 4
};

export const selectIsLoggedIn = (state) => !!state.auth.name;
export const selectLoggedInUserName = (state) => state.auth.name;
export const selectLoggedInUserID = (state) => state.auth.userID;
export const selectLoggedInUserPermissions = (state) => {
  if (state.email === null) {
    return { superadmin: false, level_by_org: {} };
  }
  const { permissions, super_admin } = state.auth;
  var level_by_org = {};
  permissions.forEach(({ organisation_id, role }) => {
    level_by_org[organisation_id] = levelmap[role];
  });
  return { super_admin, level_by_org };
};
export const selectLoggedInGravatar = (state) => {
  let { email } = state.auth;
  return (
    state.auth.email &&
    `https://www.gravatar.com/avatar/${md5(email.toLowerCase())}.jpg?d=404`
  );
};
export const selectAuthorizationHeader = (state) => ({
  Authorization: `Bearer ${state.auth.sessionID}`
});
export const reducer = slice.reducer;

export function preloadedState() {
  const data = getData();
  if (data == null) return initialState;
  const now = new Date();
  const expiry = new Date(data.expiry);
  if (now.valueOf() > expiry.valueOf()) return initialState;
  return data;
}
