import axios from 'axios';
import { selectAuthorizationHeader } from '../store/slices/auth';

let store;

export const injectStore = (_store) => {
  store = _store;
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_PATH,
  withCredentials: true,
  timeout: 60000
});

instance.interceptors.request.use((config) => {
  if (config.baseURL !== process.env.REACT_APP_BASE_PATH) {
    return config;
  }
  return config;
});

instance.interceptors.request.use(
  (config) => {
    if (config.headers.Authorization === false) {
      const { Authorization, ...rest } = config.headers;
      return {
        ...config,
        headers: { ...rest }
      };
    }
    if (config.headers.Authorization) return config;
    const token = selectAuthorizationHeader(store.getState());
    return {
      ...config,
      headers: { ...config.headers, ...token }
    };
  },
  (error) => Promise.reject(error)
);

export default instance;

export const axiosBaseQuery =
  () =>
  async ({ url, method, data, params, ...rest }) => {
    try {
      const result = await instance({ url, method, data, params, ...rest });
      return { data: result.data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data
          }
        };
      }
      throw new Error('Unknown error');
    }
  };
