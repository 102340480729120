import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMachines, patchMachines } from '../store/slices/machines';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { fetchSessions } from '../store/slices/sessions';
import { territories } from '../constants';
import { codeToOption } from '../utils';

function SummaryTable(props) {
  const { machineKeys, namesByMachineID, statsByMachine } = props;

  function fmtOptDate(date) {
    return date && DateTime.fromSeconds(date).toISODate();
  }

  return (
    <TableContainer component={Paper} elevation={9} style={{ margin: '20px' }}>
      <Table sx={{ minwidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow key={'12312311'} style={{ height: '60px' }}>
            <TableCell style={{ paddingLeft: '40px' }}>Machine</TableCell>
            <TableCell>Earliest session</TableCell>
            <TableCell>Latest session</TableCell>
            <TableCell>Number of sessions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {machineKeys.map((id) => {
            const name = namesByMachineID[id];
            const { count, earliest, latest } = statsByMachine[id];
            return (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ height: '60px' }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ paddingLeft: '40px' }}
                >
                  {name}
                </TableCell>
                <TableCell>{fmtOptDate(earliest)}</TableCell>
                <TableCell>{fmtOptDate(latest)}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Machines = () => {
  const dispatch = useDispatch();
  const statsByMachine = {};
  const namesByMachineID = {};
  const machineKeys = [];
  const [open, setOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState(null);
  const { data: machines } = useSelector((s) => s.machines);
  const { data: sessions } = useSelector((s) => s.sessions);
  const states = territories.data.map(codeToOption);
  // async effects -------------------------------------------------
  useEffect(() => {
    dispatch(fetchMachines());
    dispatch(fetchSessions());
  }, [dispatch]);

  // callbacks
  const handleOpenDialog = useCallback(
    (id) => {
      const machine = machines.find(({ id: mId }) => mId === id);
      setEditing(machine);
      setSelectedState(machine?.state_code || '');
      setOpen(true);
    },
    [machines]
  );

  const handleConfirmDialog = useCallback(() => {
    const { id } = editing;
    if (selectedState) {
      dispatch(
        patchMachines(
          {
            state_code: selectedState.toString()
          },
          id
        )
      );
    }
    setOpen(false);
  }, [dispatch, editing, selectedState]);

  const handleCloseDialog = useCallback(() => {
    setEditing(null);
    setOpen(false);
  }, []);

  const handleChangeSelect = useCallback((event) => {
    setSelectedState(event.target.value || null);
  }, []);

  if (machines.length === 0) {
    return <div>loading...</div>;
  }

  machines.forEach(({ id, name }) => {
    statsByMachine[id] = {
      count: 0,
      earliest: null,
      latest: null
    };
    namesByMachineID[id] = name;
    machineKeys.push(id);
  });

  sessions.forEach(({ machine_id }) => {
    if (!(machine_id in statsByMachine)) {
      statsByMachine[machine_id] = {
        count: 0,
        earliest: null,
        latest: null
      };
      namesByMachineID[machine_id] = machine_id;
      machineKeys.push(machine_id);
      console.log('adding unknown machine with id', machine_id);
    }
  });

  sessions.forEach(({ start_time, machine_id }) => {
    statsByMachine[machine_id].count += 1;
    statsByMachine[machine_id].earliest = Math.min(
      statsByMachine[machine_id].earliest || start_time,
      start_time
    );
    statsByMachine[machine_id].latest = Math.max(
      statsByMachine[machine_id].latest || start_time,
      start_time
    );
  });

  let cardStyle = {
    height: '120px',
    background: '#fff',
    padding: '10px'
  };
  const appList = machines.map(({ name, id, state_code }, index) => (
    <Grid item sm={3} height={'120px'} key={index}>
      <Card id={`${id}-card`} raised style={cardStyle} elevation={9}>
        <h4 style={{ textAlign: 'center' }}>{name}</h4>
        <h4 style={{ textAlign: 'center' }}>
          <Button
            aria-label="State"
            value={id}
            onClick={() => handleOpenDialog(id)}
          >
            {territories.data.find(({ code }) => {
              return state_code === code;
            })?.name || 'Choose State'}
          </Button>
        </h4>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <h2>Machine summaries</h2>
      <Grid container spacing={2} style={{ margin: '20px' }}>
        {appList}
      </Grid>
      <SummaryTable
        machineKeys={machineKeys}
        namesByMachineID={namesByMachineID}
        statsByMachine={statsByMachine}
      />
      <Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose State</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                native
                value={selectedState}
                onChange={handleChangeSelect}
              >
                <option value={''}>Choose State</option>
                {states.map(({ value, label }, index) => {
                  return (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDialog}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Machines;
