import './App.css';
import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { LoginContextProvider } from './contexts/LoginContext';
import {
  keepCurrentSessionAlive,
  logout,
  selectLoggedInGravatar,
  selectLoggedInUserName
} from './store/slices/auth';
import SignIn from './pages/SignIn';
import Users from './pages/Users';
import Machines from './pages/Machines';
import Sessions from './pages/Sessions';
import Apps from './pages/Apps';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    minWidth: '1000px',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    paddingTop: '120px'
  },
  listItem: (props) => ({
    '&:hover': {
      background: props.accentDk,
      color: '#fff'
    }
  }),
  activeListItem: (props) => ({
    background: props.accentLt,
    borderRight: `3px solid ${props.accentDk}`,
    color: props.accentDk
  })
}));

const accentsKaboodle = {
  accentDk: '#b0cd10',
  accentLt: '#eaf0d2'
};
const accentsViewport = {
  accentDk: '#51b9ff',
  accentLt: '#d2e9fb'
};

function App(props) {
  const classes = useStyles([accentsViewport, accentsKaboodle][1]);
  const loggedInUserName = useSelector(selectLoggedInUserName);
  const loggedInGravatar = useSelector(selectLoggedInGravatar);
  const loggedIn = !!loggedInUserName;

  const { keepCurrentSessionAlive } = props;
  useEffect(() => {
    if (loggedIn) {
      keepCurrentSessionAlive();
    }
  }, [loggedIn, keepCurrentSessionAlive]);

  let navItems = loggedIn
    ? [
        { name: '--' },
        { path: '/apps', name: 'Apps', enabled: false },
        { path: '/machines', name: 'Machine Summaries', enabled: true },
        { path: '/sessions', name: 'Session Graphs', enabled: true },
        { path: '/users', name: 'Users', enabled: true },
        { name: '--' }
      ]
    : [{ path: '/', name: 'Sign in', enabled: true }];

  const navList = navItems.map(({ path, name, enabled }, index) =>
    name === '--' ? (
      <Divider key={index} />
    ) : (
      enabled && (
        <ListItem
          button
          component={NavLink}
          to={path}
          key={index}
          className={classes.listItem}
          activeClassName={classes.activeListItem}
          exact
        >
          <ListItemText>{name}</ListItemText>
        </ListItem>
      )
    )
  );

  // (<AppBar position={"static"} style={{paddingLeft: drawerWidth}}>
  //     <Toolbar variant={"dense"}>
  //         <Typography variant={"h6"}>
  //             Viewport Stats</Typography>
  //     </Toolbar>
  // </AppBar>)

  const loggedInContent = (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <Card
          style={{ margin: '120px 10px 10px 10px', padding: '10px' }}
          elevation={0}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              src={loggedInGravatar}
              style={{ float: 'left', marginRight: '12px' }}
            />

            {loggedInUserName}
          </Box>
        </Card>
        <List>
          {navList}
          {loggedIn && (
            <ListItem
              button
              component={Link}
              onClick={props.logout}
              key={999}
              to={'/'}
              className={classes.listItem}
            >
              <ListItemText>Sign out</ListItemText>
            </ListItem>
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Switch>
          <Route exact path={'/'} component={SignIn} />
          <Route exact path={'/apps'} component={Apps} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/machines" component={Machines} />
          <Route exact path="/sessions" component={Sessions} />
        </Switch>
      </main>
    </div>
  );
  const loggedOutContent = (
    <div className={classes.root}>
      <main className={classes.content}>
        <SignIn />
      </main>
    </div>
  );

  return (
    <LoginContextProvider user={{ name: loggedInUserName }}>
      <CssBaseline />
      {loggedIn ? loggedInContent : loggedOutContent}
    </LoginContextProvider>
  );
}

export default connect(null, { logout, keepCurrentSessionAlive })(App);
