import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import BaseButton from '../Button/BaseButton';
import { SESSIONS_FILTERS, SESSIONS_FILTERS_DATE } from '../../constants';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { useToggle } from 'react-use';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import ButtonSelect from '../Select/ButtonSelect';
import { codeToOption, namedToOption } from '../../utils';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexFlow: 'row wrap-reverse',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    flexDirection: 'row'
  },
  calendar: {
    padding: 9,
    borderRadius: 18,
    minWidth: 'auto'
  },
  left: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  right: {},
  dialog: {
    color: '#000000 !important',
    '& .Mui-selected': {
      backgroundColor: '#b0cd10 !important'
    },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      backgroundColor: '#eaf0d2 !important'
    }
  }
});

const FiltersBar = ({
  sessions = [],
  machines = [],
  territories = [],
  active = SESSIONS_FILTERS[0],
  onChange,
  onSelectedFilters
}) => {
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState(active);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [dates, setDates] = useState([DateTime.now(), DateTime.now()]);
  const maxDate = DateTime.now();

  const minDate = useMemo(() => {
    const pastYerDate = DateTime.now().minus({ years: 1 });
    const map = Array.from([
      pastYerDate,
      ...sessions.map(({ created_at }) => DateTime.fromISO(created_at))
    ]);
    return DateTime.min(...map);
  }, [sessions]);

  const handleClick = useCallback(
    (type) => {
      const typeObject = SESSIONS_FILTERS.find(({ key }) => key === type);
      onChange && onChange(typeObject);
      setActiveItem(typeObject);
    },
    [onChange]
  );

  const handleDateChange = useCallback(
    (values) => {
      setDates(values);
      toggleIsOpen(false);
      const typeObject = { ...SESSIONS_FILTERS_DATE, values };
      onChange && onChange(typeObject);
      setActiveItem(typeObject);
    },
    [onChange, toggleIsOpen]
  );

  const machinesOptions = useMemo(
    () => machines.data.map(namedToOption),
    [machines]
  );

  const territoriesOptions = useMemo(
    () => territories.data.map(codeToOption),
    [territories]
  );

  return (
    <Box className={classes.box}>
      <Box className={classes.left}>
        <ButtonSelect
          multiple={true}
          options={machinesOptions}
          onHide={onSelectedFilters}
          title="Kiosks"
          name="machines"
        />
        <ButtonSelect
          multiple={true}
          simpleTitle={true}
          options={territoriesOptions}
          onHide={onSelectedFilters}
          title="State/Territories"
          name="territories"
        />
      </Box>
      <Box className={classes.right}>
        {SESSIONS_FILTERS.map(({ key, title }) => (
          <BaseButton
            key={key}
            active={activeItem?.key === key}
            onClick={() => handleClick(key)}
            variant="contained"
          >
            {title}
          </BaseButton>
        ))}
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <BaseButton
            onClick={toggleIsOpen}
            key={SESSIONS_FILTERS[0].key}
            active={activeItem?.key === SESSIONS_FILTERS_DATE.key}
            className={classes.calendar}
          >
            <CalendarTodayOutlined fontSize="small" />
          </BaseButton>
          <MobileDateRangePicker
            className={classes.dialog}
            onClose={toggleIsOpen}
            onAccept={handleDateChange}
            allowSameDateSelection={true}
            showToolbar={false}
            calendars={1}
            open={isOpen}
            value={dates}
            onChange={() => {}}
            renderInput={() => {}}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};
export default FiltersBar;
